import clsx from "clsx";

export default function Button(props) {
  const Element = props.element ?? "button";
  const iconPosition = props.iconPosition ?? "right";
  const state = props.state ?? "idle";
  const type = props.type ?? "default";

  let outerClassNames = clsx(
    "tw-group tw-select-none tw-border tw-transition-colors tw-duration-300 tw-inline-flex tw-items-center tw-justify-between tw-gap-4 tw-h-12 tw-px-4 tw-rounded",
    type === "default" && state === "active" && "tw-bg-white tw-text-blue",
    type === "default" &&
      state === "idle" &&
      "tw-bg-white/20 tw-text-white tw-border tw-border-white/15",
    type === "outlined" && "tw-bg-transparent tw-text-white tw-border",
    Element === "select" && "w-o-format-menu tw-whitespace-nowrap tw-pr-10",
  );

  return (
    <Element
      {...props}
      className={clsx(outerClassNames, props.className, {
        "tw-opacity-50": props.disabled,
      })}
    >
      {iconPosition === "left" && props.icon}
      {props.title && Element !== "select" && (
        <span className="w-o-format-button tw-whitespace-nowrap tw-relative tw--mb-0.5">
          {props.title}
        </span>
      )}
      {iconPosition === "right" && props.icon}
      {props.children}
    </Element>
  );
}
